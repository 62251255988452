import * as Sentry from '@sentry/browser'

if (process.env.RAILS_ENV != 'development') {
  const ignoreErrors = [
    /non-error promise rejection captured/i,
    /invalid regular expression/i,
    /nothing to repeat/i,
    /illegal invocation/i,
  ]

  Sentry.init({
    dsn: process.env.SENTRY_JS_DSN,
    environment: process.env.RAILS_ENV,
    ignoreErrors,
  })

  Sentry.configureScope(function (scope) {
    scope.setTag('logger', 'javascript')
  })

  if (!window.cndVars.currentAdmin && window.cndVars.currentMemberId) {
    Sentry.setUser({
      id: window.cndVars.currentMemberId,
    })
  }

  window.Sentry = Sentry
}
